















































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import { toDisplayDateString, isDate } from '@/utils';
import i18n from '@/utils/i18n-instance';
import { RequestFunctionModel } from '@/models';

@Component({
    name: 'event-table',
    components: {},
})
export default class EventTable<T> extends Vue {
    private tableData: any | null = null;

    @Prop({required: true})
    private columnsKey!: string[];

    @Prop({required: true, default: () => ([])})
    private data!: T[];

    @Prop({required: false, default: []})
    private sortableKey?: string[];

    @Prop({required: false})
    private heading!: string[];

    @Prop({default: i18n.t('search').toString()})
    private searchPlaceholder!: string;

    @Prop({default: false})
    private enableSearch!: boolean;

    @Prop({default: false})
    private enablePagination!: boolean;

    @Prop({default: false})
    private enableLimit!: boolean;

    @Prop({default: false})
    private enableServer!: boolean;

    @Prop({required: false, default: () => ([])})
    private customTemplate!: string[];

    @Prop({default: () => ([10, 50, 100])})
    private perPageValues!: number[];

    @Prop({default: 2})
    private perPage!: number;

    @Prop({default: () => (): any => false})
    private selected!: (item: T) => boolean;

    @Prop({ default: () => async (): Promise<any> => { return {} } })
    private requestFunction!: (config: any) => RequestFunctionModel<T>

    private decoration = {
        textTrue: 'green',
        textFalse: 'red'
    };

    private eventTableKey = false;

    private mounted(): void {
        this.setData(this.data);
    }

    @Watch('data')
    private reloadData(newValue: T[]): void {
        if (newValue) {
            this.setData(newValue);
            this.$forceUpdate();
        }
    }

    private setData(data: T[]): void {
        this.tableData = {
            columns: this.columnsKey,
            data: data,
            options: {
                sortable: this.sortableKey,
                headings: this.heading,
                requestFunction: this.requestFunction,
                texts: {
                    limit: i18n.t('resultsPerPage').toString(),
                    filter: i18n.t('searchResults').toString(),
                    filterPlaceholder: this.searchPlaceholder,
                    noResults: i18n.t('noMatchingRecords').toString(),
                    page: i18n.t('page').toString(),
                },
                perPage: this.perPage,
                perPageValues: this.perPageValues,
                pagination: {
                    edge: true,
                },
            },
        };
        this.eventTableKey = !this.eventTableKey;
    }

    private formatIfDate(value: any): string {
        const text = value;
        if (typeof value === 'string' && isDate(value)) {
            return toDisplayDateString(text, i18n.locale);
        }
        if (typeof value === 'boolean') {
            this.generateStyle(value);
            return value ? 'Yes' : 'No';
        }
        return text?.toString() ?? '';
    }

    private generateClass(item: T): string {
        if (this.selected) {
            return this.selected(item) ? 'row-selected' : 'row-unselected';
        }
        return 'row-unselected';
    }

    private generateStyle(value: boolean): object {
        if (typeof value === 'string' || typeof value === 'number') {
            return {};
        }
        return {
            color: value ? this.decoration.textTrue : this.decoration.textFalse,
        };
    }
}
