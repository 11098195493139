export interface EventHistoryList {
    eventId: number;
    eventIdentifier: number;
    eventTitle: string;
    eventStart: string;
    eventStop: string;
    unavailableCapacity: number;
    availableCapacity: number;
    installedCapacity: number;
    updatedDate: string;
    updatedBy: string;
    isRead: boolean;
}

export const EventHistoryHeaders = [
    'eventIdentifier',
    'eventTitle',
    'eventStart',
    'eventStop',
    'availableCapacity',
    'unavailableCapacity',
    'updatedDate',
    'updatedBy',
    'isRead',
    'action',
]

export const EventHistorySortable = [
    'eventIdentifier',
    'eventTitle',
    'eventStart',
    'eventStop',
    'unavailableCapacity',
    'availableCapacity',
    'installedCapacity',
    'updatedDate',
    'isRead',
    'updatedBy',
]

export interface EventHistoryDetail {
    id: number;
    eventId: number;
    eventIdentifier: number;
    eventTitle: string;
    country: string;
    marketParticipant: string;
    market: string;
    selectedMarket: string | undefined;
    eventStatus: string;
    selectedEventStatus: string | undefined;
    typeOfUnavailability: string;
    selectedTypeOfUnavailability: string | undefined;
    typeOfEvent: string;
    selectedTypeOfEvent: string | undefined;
    eventStart: string;
    eventStop: string;
    unitOfMeasurement: string;
    unavailableCapacity: number;
    availableCapacity: number;
    installedCapacity: number;
    reasonOfUnavailability: string;
    remarks: string;
    remarksTransKey: string | undefined;
    fuel: string;
    biddingZone: string;
    unit: string;
    unitEICCode: string;
    marketParticipantCode: string;
    publicationTitle: string;
    publicationTitleTransKey: string | undefined;
    isRead: boolean;
    updatedBy: string;
    updatedAt: string;
    mustRunCapacity: string;
    testCapacity: string;
}
