import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/components/home/home.vue';
import HungarianEventList from '@/views/hungarian-event-list/hungarian-event-list.vue';
import HungarianEventDetails from '@/views/details-view/hungarian-event-details.vue';
import HungarianEventHistoryList from '@/views/hungarian-event-list/hungarian-event-history.vue';
import EventHistoryDetails from '@/views/details-view/event-history-details.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/',
        redirect: '/hungarian-event-list',
        name: 'home',
        component: Home,
        meta: {
            requiresAuthentication: false,
        },
    },
    {
        path: '/hungarian-event-list',
        name: 'hungarian-event-list',
        component: HungarianEventList,
        children: [
            {
                path: 'event-details',
                name: 'hungarian-event-details',
                component: HungarianEventDetails,
            },
        ],
    },
    {
        path: '/event-history',
        name: 'event-history',
        component: HungarianEventHistoryList,
        children: [
            {
                path: 'event-history-details',
                name: 'hungarian-event-history-details',
                component: EventHistoryDetails,
            },
        ],
    },
];

const router = new VueRouter({
    routes,
});

export default router;
