
































import { Component, Vue, Watch } from 'vue-property-decorator';
import EventTable from '@/components/event-table/event-table.vue';
import HungarianEventDetails from '@/views/details-view/hungarian-event-details.vue';
import BooleanLabel from '@/components/boolean-label/boolean-label.vue';
import { EventHeader, PebbleDropDown, ServerTableRequest } from '@/models';
import { EventColumnHeaders, EventColumnHeadersSortable, EventHeaderFilterModel } from '@/models/event-headers';
import { EventService } from '@/services';
import { EventBus } from '@/utils';

@Component({
    name: 'hungarian-event-list',
    components: {
        EventTable,
        HungarianEventDetails,
        BooleanLabel,
    }
})
export default class HungarianEventList extends Vue {
    private columns = EventColumnHeaders;
    private sortable = EventColumnHeadersSortable;
    private eventService: EventService = new EventService();
    private evenListData: EventHeader[] = [];
    private eventDetails: EventHeader  | null = null;
    private tableServerReuest: ServerTableRequest = {
        ascending: 0,
        byColumn: 0,
        limit: 10,
        orderBy: '',
        page: 1,
        query: ''
    }
    private filterValues: EventHeaderFilterModel = {
        AreaSids: [4109], // only hungarian units - value form Master data
        EventStatus: [732, 734, 735], // checked-accepted, remit-processed and completed events - value from Master data
        SortProperty: '',
        page: 1,
        Term: '',
        TermToLower: '',
        EventIdentifier: '',
        EventTitle: '',
        size:10,
    }

    @Watch('$route.path')
    private onQueryItemChanged(newValue: string | undefined, oldValue: string | undefined): void {
        if (newValue && newValue !== oldValue) {
            this.isDetailsView;
        }
    }

    private async mounted(): Promise<void> {
        this.isDetailsView;
        await this.getTableData(this.tableServerReuest);
    }

    private async getTableData(config: any): Promise<any> {
        this.$store.commit('loading');
        try {

            if (config.orderBy === undefined || config.orderBy === '') {
                this.filterValues.Sort = 'DESC';
                this.filterValues.SortProperty = 'UpdatedAt';
            } else {
                config.ascending === 0 ? this.filterValues.Sort = 'DESC' : this.filterValues.Sort = 'ASC';
                this.filterValues.SortProperty = this.capitalizeFirstLetter(config.orderBy);
            }

            this.filterValues.page = config.page;
            this.filterValues.size = config.limit;
            this.filterValues.EventIdentifier = config.query;

            const apiResponse =  (await this.eventService.getAllFilteredEventHeaders(this.filterValues)).result;

            return {data: apiResponse.items, count: apiResponse.totalCount};
        } catch (e) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'error.gettingEvents');
        } finally {
            this.$store.commit('loading');
        }
    }

    private async getEventDetails(data: any): Promise<void> {
        const eventId = data.eventId;
        if (data) {
            this.eventDetails = {...data};
            this.$router.push({
                name: 'hungarian-event-details',
                query: {
                    eventId: eventId
                }
            });
        }
    }

    get isDetailsView(): boolean {
        return this.$route.path === '/hungarian-event-list/event-details'? true : false;
    }


    private capitalizeFirstLetter(text: string): string {
        return text ? text.charAt(0).toUpperCase() + text.slice(1) : '';
    }


    get heading(): Record<string, any> {
        return {
            eventId: this.$i18n.t('table.id'),
            createdAt: this.$i18n.t('table.createdAt'),
            createdBy: this.$i18n.t('table.createdBy'),

            updatedAt: this.$i18n.t('table.updatedAt'),
            updatedBy: this.$i18n.t('table.updatedBy'),

            creatorId: this.$i18n.t('table.creatorId'),
            approverFourEyePrincipleId: this.$i18n.t('table.approver'),
            lastEditedById: this.$i18n.t('table.lastEditedById'),


            eventIdentifier: this.$i18n.t('table.id'),
            eventTitle: this.$i18n.t('table.title'),
            eventDescription: this.$i18n.t('table.eventDescription'),
            netCapacityTec: this.$i18n.t('table.netCapacityTec'),
            availCapacityUnit: this.$i18n.t('table.availability'),
            unAvailCapacityUnit: this.$i18n.t('table.unAvailability'),
            minCapacityEvent: this.$i18n.t('table.minCapacityEvent'),
            netMinCapacityTec: this.$i18n.t('table.netMinCapacityTec'),

            utcStartTime: this.$i18n.t('table.start'),
            utcEndTime: this.$i18n.t('table.end'),
            expectedUtcEndTime: this.$i18n.t('table.expectedUtcEndTime'),
            action: '',
        };
    }
}

