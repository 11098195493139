









































import { Component, Vue } from 'vue-property-decorator';
import UserProfile from './user-profile/user-profile.vue';
import { MenuEntry } from 'adam.ui-core/dist/src/lib/components/AppDrawer/AppDrawer';
import { AppService } from '@/services';
import { Application } from '@/models';
import Snackbar from '@/components/snackbar/snackbar.vue';
import { EventBus } from '@/utils';
import i18n from '@/utils/i18n-instance';

@Component({
    name: 'header-wrapper',
    components: {
        userProfile: UserProfile,
        snackbar: Snackbar,
    },
})
export default class HeaderWrapper extends Vue {
    private appService: AppService = new AppService();
    private applications: MenuEntry[] = [];
    private user = {
        name: '',
        email: '',
    };

    private async mounted(): Promise<void> {
        await this.init();
    }

    private async init(): Promise<void> {
        await this.setProfileData();
        await this.loadApps();
    }

    private async setProfileData(): Promise<void> {
        const profile: any = await this.$sdk.$authenticator?.getAccount();
        if (profile) {
            this.user.name = profile.name;
            this.user.email = profile.username;
        }
    }

    private async loadApps(): Promise<void> {
        this.$store.commit('loading');
        try {
            const apps = (await this.appService.getApps()).result.items;
            this.applications = this.processApps(apps);
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingApplications');
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private processApps(apps: Application[]): MenuEntry[] {
        return apps.map((app: Application): MenuEntry => ({
            id: app.useCaseId,
            type: app.hasAccess ? 'APPLICATION' : '',
            label: app.name,
            iconId: this.getLogo(app.logo),
            url: app.url ? app.url : undefined,
        })).sort((a: MenuEntry, b: MenuEntry) => {
            const nameA = a?.label?.toLowerCase();
            const nameB = b?.label?.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    private clickDrawer(): void {
        (this.$refs.appDrawer as any).open = true;
    }

    private handleAppClick(app: Application): void {
        if (app.url) {
            window.open(app.url);
        }
    }

    get isTestEnvironment(): boolean {
        return !this.$store.getters['isProductionEnv'];
    }

    private getLogo(img: string): string {
        try {
            require(`adam.ui-core/assets/icons/${img}.svg`);
            return img;
        } catch (e) {
            return 'app-generic';
        }
    }

    get getAppDrawerLabels(): any {
        return { applications: i18n.t('appsWithAccess'), miscellaneous: i18n.t('appsWithoutAccess') };
    }
}
