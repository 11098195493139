import BaseService from '@/utils/base-service';
import { ApiResponse, ApiResponseResultList, TsoEventModel, EventReasonModel, ApiResponseList, EventHistoryList, EventHistoryDetail } from '@/models';
import querystring from 'querystring';
import { ApiResponseResult } from '@/models/api-response';
import { TsoData } from '@/models/event-tso';
import Vue from 'vue';

/**
 * @name HungarianEventService
 * @description This class is used to get Hungarian TSO data for the form.
 */
export class HungarianEventService extends BaseService {
    protected headers = {'Ocp-Apim-Subscription-Key': process.env.VUE_APP_APIM_SUBSCRIPTION}
    constructor() {
        super(process.env.VUE_APP_HUNGARIAN_TSO_SCOPE, process.env.VUE_APP_API_BASE_URL + '/api/v1')
    }

    public async getEventDetails(): Promise<ApiResponse<ApiResponseResultList<TsoEventModel>>> {
        return (await Vue.prototype.$sdk.$http?.$generic.request.api.get(`HungaryEventDetails`, {headers: this.headers})).data;
    }

    public async getReasonDetails(): Promise<ApiResponse<ApiResponseResultList<EventReasonModel>>> {
        return (await Vue.prototype.$sdk.$http?.$generic.request.api.get(`HungaryReasonsDetails`, {headers: this.headers})).data;
    }

    public async saveEventDetails(EventDetails: any): Promise<ApiResponse<TsoData>> {
        return (await Vue.prototype.$sdk.$http?.$generic.request.api.post(`CaptureEventDetails`, EventDetails, {headers: this.headers})).data;
    }

    public async editDetails(EventDetails: any): Promise<ApiResponse<TsoData>> {
        return (await Vue.prototype.$sdk.$http?.$generic.request.api.put(`EditEventDetails`, EventDetails, {headers: this.headers})).data;
    }

    public async getEventHistoryList(): Promise<ApiResponse<ApiResponseList<EventHistoryList>>> {
        return (await Vue.prototype.$sdk.$http?.$generic.request.api.get(`GetEventList`, {headers: this.headers})).data;
    }

    public async getEventHistoryDetails(queryObject: {EventId: number}): Promise<ApiResponse<ApiResponseResult<EventHistoryDetail>>> {
        const query = querystring.stringify(queryObject);
        return (await Vue.prototype.$sdk.$http?.$generic.request.api.get(`GetEventDetails`+ `${query ? '?' + query : ''}`, {headers: this.headers})).data;
    }
}
