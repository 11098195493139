import { ApiResponse, EventHeader, EventHeaderFilterModel, EventListResult } from '@/models';
import BaseService from '@/utils/base-service';
import querystring from 'querystring';
import Vue from 'vue';

export class EventService extends BaseService {
    private endpoint = ''
    constructor() {
        super(process.env.VUE_APP_MODE_SCOPE, process.env.VUE_APP_EXTERNAL_APP_MODE_URL)
        this.endpoint = 'event-headers-list';
    }

    public async getAllFilteredEventHeaders(filter: EventHeaderFilterModel): Promise<ApiResponse<EventListResult>> {
        const query = querystring.stringify(filter as any);
        return (await Vue.prototype.$sdk.$http?.$generic.request.api.get(`/${this.endpoint}` + `${query ? '?' + query : ''}`)).data;
    }

    public async getEventById(eventId: number): Promise<ApiResponse<EventHeader>> {
        return (await Vue.prototype.$sdk.$http?.$generic.request.api.get(`/event-headers/${eventId}`)).data;
    }
}