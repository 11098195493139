








import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { TabModel } from '@/models';

@Component({
    name: 'nav-tabs',
})
export default class NavTabs extends Vue {
    private linkItems: TabModel[] = [];

    @Watch('$route.path')
    private onQueryItemChanged(newValue: string): void {
        if (newValue) {
            this.activateTab();
        }
    }

    private created(): void {
        this.activateTab();
    }

    private activateTab(): void {
        this.$router.onReady(() => {
            const currentPath = this.$router.currentRoute.path;
            const temp: TabModel[] = this.generateLinkItems();
            temp.forEach((value: TabModel) => {
                value.isActive = currentPath.includes(value.href.split('/')[1]) ?? false;
            });
            this.linkItems = temp;
        });
    }

    private onTabClicked(value: TabModel): void {
        this.$router.push(value.href);
    }

    private generateLinkItems(): TabModel[] {
        return [
            {
                displayName: this.$t('hungarianEvents').toString(),
                href: '/hungarian-event-list',
                isActive: false,
            },
            {
                displayName: this.$t('hungarianEventHistory').toString(),
                href: '/event-history',
                isActive: false,
            },
        ];
    }
}
