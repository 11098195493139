/* eslint-disable @typescript-eslint/no-var-requires */
/* tslint:disable: no-var-requires */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { LANGUAGES } from './languages';

Vue.use(VueI18n);

const lang = 'en-GB';

function loadLocaleMessages(): any {
    let messages: any = {};
    Object.keys(LANGUAGES).forEach((key: any) => {
        const file = LANGUAGES[key];
        const ft = require(`../locales/${file}.json`);
        messages = {...messages, ...ft};
    });
    return messages;
}

const i18n = new VueI18n({
    locale: lang,
    fallbackLocale: lang,
    messages: loadLocaleMessages(),
});

export default i18n;
