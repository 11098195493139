























































import { PebbleDropDown } from '@/models';
import { EventBus } from '@/utils';
import i18n from '@/utils/i18n-instance';
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({
    name: 'event-form-drop-down',
})
export default class EventFormDropdown extends Vue {

    @Prop({ default: true })
    private showCopyButton!: boolean;

    @Prop({ required: true })
    private options!: PebbleDropDown[];

    @Prop({ required: true })
    private label!: string;

    @Prop({ required: true })
    private value!: string;

    @Prop({ default: '' })
    private placeholder!: string;

    @Prop({ default: false })
    private readonly!: boolean;

    @Prop({ required: true })
    private id!: string;

    @Prop({ default: false })
    private required!: boolean

    private selection = '';

    private created(): void {
        this.selection = i18n.t(this.value, 'en-GB').toString();
    }

    get propValue(): string {
        return this.value;
    }

    set propValue(value: string) {
        this.$emit('update-value', value, this.label);
    }

    private copyField(id: string): void {
        const textElement: any = document.getElementById(id);
        if (textElement) {
            textElement.select();
            document.execCommand('copy');
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'copiedToClipboard');
        }
    }

    private onChange(value: string): void {
        this.selection = i18n.t(value, 'en-GB').toString();
        this.$emit('on-change', value, this.label);
        this.$forceUpdate();
    }

    get translatedOptions(): PebbleDropDown[] {
        const options: PebbleDropDown[] = [];
        this.options.map((data) => {
            if (data.translationKey) {
                options.push({
                    label: this.getTranslatedValue(data.translationKey),
                    value: data.value,
                })
            } else {
                options.push({
                    label: data.label,
                    value: data.value,
                })
            }
        });
        return options;
    }

    get hungarianLabel(): string {
        return this.getTranslatedValue(this.label);
    }

    private getTranslatedValue(key: string): string {
        return i18n.t(key, 'hu-HU').toString();
    }
}
