







import { Component, Vue } from 'vue-property-decorator';
// import { HomeGetRequest, HomeServiceProxy } from '@/service-proxies/service-proxies.g';

@Component({
    name: 'home',
})
export default class Home extends Vue {
    // TODO: this is experimental at the moment and can be left commented.
    // private async mounted(): Promise<void> {
    //     const serviceProxy = new HomeServiceProxy('https://localhost:5009');
    //     console.log(await serviceProxy.get2(new HomeGetRequest(), undefined));
    // }
}

