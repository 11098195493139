




























































































import {Component, Vue} from 'vue-property-decorator';
import { EventBus } from '@/utils/eventbus';
import clickOutside from '@/directives/click-outside';

@Component({
    directives: {
        clickOutside,
    },
})
export default class UserProfile extends Vue {
    private version: string | undefined = process.env.VUE_APP_VERSION;
    private apiUrl: string | undefined = process.env.VUE_APP_API_BASE_URL;
    private swaggerURL: string | undefined = process.env.VUE_APP_API_SWAGGER_URL;
    private detailsToggled = false;
    private scope = process.env.VUE_APP_HUNGARIAN_TSO_SCOPE;

    private logOut(): void {
        this.$sdk.$authenticator?.logout();
    }

    private async copyToken(): Promise<void> {
        const textElement: any = document.getElementById('copyText');
        if (textElement) {
            const token = await this.$sdk.$authenticator?.getToken(this.scope);
            textElement.value = '';
            textElement.value = `Bearer ${token}`;
            textElement.select();
            document.execCommand('copy');
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'copiedToClipboard');
        } else {
            throw new Error('Copy text element is undefined');
        }
    }

    get isTestEnvironment(): boolean {
        return !this.$store.getters['isProductionEnv'];
    }

    private redirectToAppStoreFeedbackPage (): void {
        window.open(`${process.env.VUE_APP_APP_STORE_UI_URL}/contact-page/${process.env.VUE_APP_USE_CASE_ID}?isFeedback=true`);
    }

    private reportIncident(): void {
        window.open(`${process.env.VUE_APP_APP_STORE_UI_URL}/incident-page/${process.env.VUE_APP_USE_CASE_ID}`);
    }


    private offFocus(): void {
        if (this.detailsToggled) {
            this.detailsToggled = false;
        }
    }
}
