



















import { Component, Vue } from 'vue-property-decorator';
import HeaderWrapper from '@/components/header/header.vue';
import Snackbar from '@/components/snackbar/snackbar.vue';
import Loader from '@/components/loader/loader.vue';
import NavTabs from '@/components/nav-tabs/nav-tabs.vue';
import './main.less';

@Component({
    name: 'app',
    components: {
        HeaderWrapper,
        Snackbar,
        Loader,
        NavTabs,
    },
})
export default class App extends Vue {
    private signedIn = false;
    private leftPanelIsOpen = false;
    private arePremissionSet = false;

    private async mounted(): Promise<void> {
        await this.$sdk.$authenticator?.loadAuthModule();
        await this.$sdk.$authenticator?.attemptSsoSilent('loginRedirect');

        this.signedIn = (await this.$sdk.$authenticator?.isAuthenticated()) as boolean;

        if (this.signedIn) {
            const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID);
            this.arePremissionSet = await this.$sdk.$permissions?.setPermissions(useCaseId) as boolean;
        }
    }

}
