











































































































































































































































































































import { EventBus, EventTSoDataHelper, EVENT_STATUS_TRANSLATION_KEYS, EVENT_TRANSLATION_KEYS, MARKET_TRANSLATION_KEYS, UNAVAILABILITY_TRANSLATION_KEYS } from '@/utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import EventFormTextFields from '@/components/pebble-form-element/event-form-text-feilds.vue';
import EventFormDropdown from '@/components/pebble-form-element/event-form-drop-down.vue';
import PebbleCheckBox from '@/components/pebble-form-element/pebble-checkbox.vue';
import { EventHeader, PebbleDropDown } from '@/models';
import { REASON_TRANSALATION_KEYS } from '@/utils/option-translation-keys';
import i18n from '@/utils/i18n-instance';
import { EventReasonModel, TsoData } from '@/models/event-tso';
import { HungarianEventService } from '@/services';
import { EnumEventTypeSid } from '@/utils/mocks/event-detail-response';

@Component({
    name: 'event-details-form',
    components: {
        eventFormTextFeilds: EventFormTextFields,
        eventFormDropDown: EventFormDropdown,
        pebbleCheckBox: PebbleCheckBox,
    }
})
export default class EventDetailsForm extends Vue {

    @Prop({ required: true })
    private data!: EventHeader;

    private hungaryEventService: HungarianEventService = new HungarianEventService();
    private tsoData: TsoData | null = null;
    private reasonsData: EventReasonModel[] = [];
    private isPublished = false;
    private isRepublish = false;
    private isSaveDisabled = false;
    private translatedDescription = '';
    private selectedReason = '';
    private reasonOfUnavailability = '';
    private selectedSubCategory = '';

    @Watch('data')
    private async updateData(newValue: EventHeader): Promise<void> {
        if (newValue) {
            await this.getModifiedData();
            this.setTypeOfUnavailability();
        }
    }

    private async mounted(): Promise<void> {
        await this.init();
        EventBus.$on(EventBus.VIEWS.EVENT_DETAILS.REASON_CHANGED,
            (reason: string) => this.getSelectedReason(reason));
    }

    private async init(): Promise<void> {
        this.$store.commit('loading');
        Promise.all([
            this.getModifiedData(),
            this.getReasonsData(),
            this.getIsPublished(),
        ]);
        this.$store.commit('loading');
    }

    private getSelectedReason(value: string): void {
        this.selectedSubCategory = '';
        this.selectedReason = value;
        if (this.tsoData) {
            this.tsoData.remarks = '';
            this.setInitialMaxLoad();
        }
        this.getDescription(value);
        this.showLightBox();
    }

    private setInitialMaxLoad(): void {
        if (this.tsoData) {
            this.tsoData.mustRunCapacity = 0;
            this.tsoData.testCapacity = 0;

            if (this.isTest) {
                this.tsoData.testCapacity = this.data.adhocHeader.maximumOutput;
            }
            if (this.isMustRun) {
                this.tsoData.mustRunCapacity = this.data.adhocHeader.maximumOutput;
            }
        }
    }

    private onSelect(value: string, fieldName: string): void {
        if (this.isLightBoxAvailable && fieldName === 'technicalIssue') {
            this.selectedSubCategory = value;
        }
        if (fieldName === 'eventStatus' && this.tsoData) {
            this.tsoData.selectedEventStatus = value;
        }
        if (fieldName === 'market' && this.tsoData) {
            this.tsoData.selectedMarket = value;
        }
        if (fieldName === 'typeOfUnavailability' && this.tsoData) {
            this.tsoData.selectedTypeOfUnavailability = value;
        }
        if (fieldName === 'typeOfEvent' && this.tsoData) {
            this.tsoData.selectedTypeOfEvent = value;
        }
    }

    private async getModifiedData(): Promise<void> {
        this.$store.commit('loading');
        try {
            const data = (await this.hungaryEventService.getEventDetails()).result;
            this.tsoData = EventTSoDataHelper.getModifiedApiResponse(data.result);
            this.setReactData();

            this.setTypeOfUnavailability();

            if (this.tsoData) {
                this.tsoData.selectedMarket = this.marketOptions[0].value;
                this.tsoData.selectedEventStatus = this.eventStatusOptions[0].value;
                this.tsoData.selectedTypeOfEvent = this.typeOfEventOptions[0].value;
            }
        } catch (e) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'error.gettingEventDetails');
        } finally {
            this.$store.commit('loading');
        }
    }

    private async getReasonsData(): Promise<void> {
        this.$store.commit('loading');
        try {
            const data = (await this.hungaryEventService.getReasonDetails()).result;
            this.reasonsData = data.result;
        } catch (e) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'error.gettingReasonDetails');
        } finally {
            this.$store.commit('loading');
        }
    }

    private setReactData(): void {
        if (this.tsoData && this.data) {
            this.tsoData.eventStart = this.data.utcStartTime;
            this.tsoData.eventStop = this.data.utcEndTime;
            this.tsoData.installedCapacity = this.data.netCapacityTec;
            this.tsoData.enumEventReasonEexSid = this.data.enumEventReasonEexSid;

            this.tsoData.unavailableCapacity = this.isAdhocEvent ? 
                this.data.netCapacityTec - this.data.adhocHeader.maximumOutput : 
                this.data.unAvailCapacityUnit;
            
            this.tsoData.availableCapacity = this.isAdhocEvent ?
                this.data.adhocHeader.maximumOutput :
                this.data.availCapacityUnit;
        }
    }

    private goToEventHistory(): void {
        if (this.data.eventId) {
            this.$router.push({
                name: 'hungarian-event-history-details',
                query: {
                    eventId: this.data.eventId.toString()
                }
            });
        }
    }

    get marketOptions(): PebbleDropDown[] {
        const options = this.getOptions('market', MARKET_TRANSLATION_KEYS);
        this.$store.commit('setMarketOptions', options)
        return options;
    }

    get eventStatusOptions(): PebbleDropDown[] {
        return this.getOptions('eventStatus', EVENT_STATUS_TRANSLATION_KEYS);
    }

    get typeOfUnavailabilityOptions(): PebbleDropDown[] {
        return this.getOptions('typeOfUnavailability', UNAVAILABILITY_TRANSLATION_KEYS);
    }

    get typeOfEventOptions(): PebbleDropDown[] {
        return this.getOptions('typeOfEvent', EVENT_TRANSLATION_KEYS)
    }

    get reasonOptions(): PebbleDropDown[] {
        this.reasonsData.find((data: EventReasonModel) => {
            if (data.reasonCategoryName === i18n.t('unplannedUnavailability', 'en-GB') && this.tsoData) {
                this.tsoData.reasonSubCatagories = data.reasonSubCategoryName;
            }
        })
        return this.getOptions('reasonSubCatagories', REASON_TRANSALATION_KEYS);
    }

    get subCatTranslationKey(): string {
        if (this.selectedReason === 'energyVolumeRestrictions' && this.tsoData) {

            this.tsoData.remarks = 'field.energyVolumeRemark';
            return 'field.energyVolumeRemark';
        }
        if (this.selectedReason === 'plannedUnavailability' && this.tsoData) {
            this.tsoData.remarks = 'field.plannedUnavailabilityRemark';
            return 'field.plannedUnavailabilityRemark';
        }
        return '';
    }

    private getOptions(fieldName: string, translationKeys: any[]): PebbleDropDown[] {
        const options: PebbleDropDown[] = [];
        if (this.tsoData !== undefined && this.tsoData) {
            this.tsoData[fieldName].map((data: any) => {
                translationKeys.forEach((tranData) => {
                    if (tranData.value === data) {
                        options.push({
                            label: data,
                            value: tranData.key,
                            translationKey: tranData.key
                        })
                    }
                })
            });
            return options;
        }
        return [];
    }

    private toggleLightBox(): void {
        if ((this.$refs.reasonsTemplateView) !== undefined) {
            (this.$refs.reasonsTemplateView as any).isOpen =
                !(this.$refs.reasonsTemplateView as any).isOpen;
        }
    }


    private showLightBox(): void {
        if (this.isLightBoxAvailable && this.$refs.reasonsTemplateView ) {
            (this.$refs.reasonsTemplateView as any).isOpen = true;
        }
    }

    get isLightBoxAvailable(): boolean {
        if (this.isUnplannedUnavailability || this.isEventPublication ||
            this.isMustRun || this.isTest) {
            return true;
        }
        return false;
    }

    get isUnplannedUnavailability(): boolean {
        return this.selectedReason === 'unplannedUnavailability' ? true : false;
    }

    get isEventPublication(): boolean {
        return this.selectedReason === 'eventPublication' ? true : false;
    }

    get isMustRun(): boolean {
        return this.selectedReason === 'mustRun' ? true : false;
    }

    get isTest(): boolean {
        return this.selectedReason === 'test' ? true : false;
    }

    get isAdhocEvent(): boolean {
        return this.data.enumEventTypeSid != 703;
    }

    private async getIsPublished(): Promise<void> {
        if (this.data.eventId) {
            const data = (await this.hungaryEventService.getEventHistoryDetails({EventId: this.data.eventId})).result.result;
            this.isPublished = data.isRead;
            this.disableSave();
            if (this.isPublished) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'eventPublishedMessage');
            }
        }
    }

    private setTypeOfUnavailability(): void {
        if (this.data.enumEventReasonEexSid === 713 && this.tsoData) {
            this.tsoData.selectedTypeOfUnavailability = this.typeOfUnavailabilityOptions[0].value;
        }
        if (this.data.enumEventReasonEexSid === 712 && this.tsoData) {
            this.tsoData.selectedTypeOfUnavailability = this.typeOfUnavailabilityOptions[1].value;
        }
    }

    private onConfirm(): void {
        this.getDescription(this.selectedReason);
        this.toggleLightBox();

    }

    private updateFlag(value: boolean, name: string): void {
        if (this.tsoData && name === 'published') {
            this.tsoData.isRead = value;
        }
        if (this.tsoData && name === 'rePublish') {
            this.isRepublish = value;
            this.isRepublish ? this.isSaveDisabled = false : this.isSaveDisabled = true;
        }
    }

    private getMustRunOrTest(value: string): void {
        if (this.isMustRun && this.tsoData) {
            this.tsoData.mustRunCapacity = +value;
        }
        if (this.isTest && this.tsoData) {
            this.tsoData.testCapacity = +value
        }
    }

    private calculateAvailableCapacity(value: string): number {
        if (this.tsoData) {
            this.tsoData.unavailableCapacity = +value;
            return this.tsoData.availableCapacity = this.tsoData.installedCapacity - this.tsoData.unavailableCapacity;
        }
        return 0;
    }

    private downloadPdf(): void {
        this.$store.commit('loading');
        try {
            this.setReactData();
            if (this.tsoData) {
                const title = this.data.eventTitle + '-' + this.data.eventIdentifier;
                EventTSoDataHelper.downloadData(this.tsoData, title, this.translatedDescription);
            }
        } catch (e) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'error.downloadingPdf');
        } finally {
            this.$store.commit('loading');
        }
    }

    private disableSave(): void {
        if (this.isPublished) {
            this.isSaveDisabled = true;
        }
    }

    private async save(): Promise<void> {
        this.$store.commit('loading');
        try {
            const selectedFields = {
                market: '',
                eventStatus: '',
                typeOfUnavailability: '',
                typeOfEvent: ''
            }
            if (this.tsoData) {
                selectedFields.market = i18n.t(this.tsoData.selectedMarket, 'en-GB').toString();
                selectedFields.eventStatus = i18n.t(this.tsoData.selectedEventStatus, 'en-GB').toString();
                selectedFields.typeOfUnavailability = i18n.t(this.tsoData.selectedTypeOfUnavailability, 'en-GB').toString();
                selectedFields.typeOfEvent = i18n.t(this.tsoData.selectedTypeOfEvent, 'en-GB').toString();
                if (this.isRepublish) {
                    this.tsoData.isRead = true;
                }
            }

            const requestObject = {
                eventId: this.data.eventId,
                eventIdentifier: this.data.eventIdentifier,
                eventTitle: this.data.eventTitle,
                country: this.tsoData?.country,
                marketParticipant: this.tsoData?.marketParticipant,
                market: selectedFields.market,
                eventStatus: selectedFields.eventStatus,
                typeOfUnavailability: selectedFields.typeOfUnavailability,
                typeOfEvent: selectedFields.typeOfEvent,
                eventStart: this.tsoData?.eventStart,
                eventStop: this.tsoData?.eventStop,
                unitOfMeasurement: this.tsoData?.unitOfMeasurement,
                unavailableCapacity: this.tsoData?.unavailableCapacity,
                availableCapacity: this.tsoData?.availableCapacity,
                installedCapacity: this.tsoData?.installedCapacity,
                reasonOfUnavailability: this.reasonOfUnavailability,
                remarks: i18n.t(this.selectedSubCategory, 'en-GB').toString(),
                fuel: this.tsoData?.fuel,
                biddingZone: this.tsoData?.biddingZone,
                unit: this.tsoData?.affectedAsset,
                unitEICCode: this.tsoData?.affectedAssetCode,
                marketParticipantCode: this.tsoData?.marketParticipantCode,
                publicationTitle: i18n.t(this.selectedReason, 'en-GB').toString(),
                isRead: this.tsoData?.isRead,
                mustRunCapacity: this.tsoData?.mustRunCapacity,
                testCapacity: this.tsoData?.testCapacity
            }
            const savedEventDetails = (await this.hungaryEventService.saveEventDetails(requestObject)).result;
            if (savedEventDetails.eventExists) {
                await this.hungaryEventService.editDetails(requestObject);
            }
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'changesSavedSuccessFully');
            this.goToEventHistory();
        } catch (e) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'error.savingEventDetails');
        } finally {
            this.$store.commit('loading');
        }
    }

    private getDescription(reason: string): void {
        this.reasonsData.find((data: any) => {
            if ((i18n.t(reason, 'en-GB') === data.reasonCategoryName) && this.tsoData) {
                const hungarianDescrition = i18n.t('field.description.' + reason, 'hu-HU').toString();
                if (!this.isLightBoxAvailable){
                    this.reasonOfUnavailability = data.description;
                    this.translatedDescription = hungarianDescrition;
                    this.selectedSubCategory = data.reasonSubCategoryName[0];
                }

                if ((this.isUnplannedUnavailability || this.isEventPublication) && this.selectedSubCategory !== '') {
                    this.reasonOfUnavailability = data.description.replace('Due to', 'Due to ' + i18n.t(this.selectedSubCategory, 'en-GB').toString());
                    this.translatedDescription = hungarianDescrition.replace('be ', 'be ' + i18n.t(this.selectedSubCategory, 'hu-HU').toString() + ' ')
                    this.tsoData.remarks = this.selectedSubCategory;
                }

                if (this.isMustRun && this.tsoData) {
                    this.reasonOfUnavailability = data.description.replace('350', this.tsoData.mustRunCapacity);
                    this.translatedDescription = hungarianDescrition.replace('350', this.tsoData.mustRunCapacity.toString());
                }

                if (this.isTest && this.tsoData) {
                    this.reasonOfUnavailability = data.description.replace('400', this.tsoData.testCapacity);
                    this.translatedDescription = hungarianDescrition.replace('400', this.tsoData.testCapacity.toString());
                }

                this.tsoData.reasonOfUnavailability = this.reasonOfUnavailability;
                this.tsoData.publicationTitle = reason;
            }
        })
    }
}

