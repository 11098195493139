import Vue from 'vue';
import App from './App.vue';
import VueI18n from 'vue-i18n';
import router from './router';
import store from './store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCopy, faCaretRight, faCaretUp, faCaretDown, faSort, faGripLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'adam.ui-core/dist/umd';
import './registerServiceWorker';
import { VNode } from 'vue/types/umd';
import PebbleUI from '@enerlytics/pebble-ui/dist/pebble-ui';
import { CoodeSdkPlugin } from '@coode/fe-sdk';
import {sdkConfig} from '@/utils';

Vue.config.productionTip = false;

Vue.component('font-awesome-icon', FontAwesomeIcon);

library.add(faCopy as any, faCaretRight as any, faCaretUp as any, faCaretDown as any, faSort as any, faGripLines as any);

Vue.use(VueI18n);
Vue.use(PebbleUI);
Vue.use(new CoodeSdkPlugin(), sdkConfig);

const lang = 'en-GB';
const i18n = new VueI18n({
    locale: lang,
    messages: require(`./locales/${lang}.json`),
});

new Vue({
    i18n,
    router,
    store,
    render: (h): VNode => h(App),
}).$mount('#app');
