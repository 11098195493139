import Vue from 'vue';

export const EventBus = new Vue({
    data(): any {
        return {
            GLOBAL: {
                SHOW_SNACKBAR: 'global.show-snackbar',
            },
            VIEWS: {
                EVENT_DETAILS: {
                    REASON_CHANGED: 'view.eventDetails.reasonChanged',
                }
            },
            DOWNLOAD: {
                PDF: 'download.pdf',
            }
        };
    },
});
