






























import { Component, Vue, Watch } from 'vue-property-decorator';
import EventTable from '@/components/event-table/event-table.vue';
import HungarianEventDetails from '@/views/details-view/hungarian-event-details.vue';
import EventHistoryDetails from '@/views/details-view/event-history-details.vue';
import BooleanLabel from '@/components/boolean-label/boolean-label.vue';
import { EventHistoryHeaders, EventHistoryList, EventHistorySortable } from '@/models';
import { EventBus } from '@/utils';
import i18n from '@/utils/i18n-instance';
import { HungarianEventService } from '@/services';

@Component({
    name: 'hungarian-event-history-list',
    components: {
        EventTable,
        HungarianEventDetails,
        EventHistoryDetails,
        BooleanLabel,
    }
})
export default class HungarianEventHistoryList extends Vue {
    private columns = EventHistoryHeaders;
    private sortable = EventHistorySortable;
    private eventService = new HungarianEventService();
    private evenListData: EventHistoryList[] = [];
    private eventDetails: EventHistoryList  | null = null;

    @Watch('$route.path')
    private onQueryItemChanged(newValue: string | undefined, oldValue: string | undefined): void {
        if (newValue && newValue !== oldValue) {
            this.isDetailsView;
        }
    }

    private async mounted(): Promise<void> {
        this.isDetailsView;
        await this.getTableData();
    }

    private async getTableData(): Promise<void> {
        this.$store.commit('loading');
        try {
            this.evenListData = (await this.eventService.getEventHistoryList()).result.items;
        } catch (e) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'error.gettingEvents');
        } finally {
            this.$store.commit('loading');
        }
    }

    private async getEventDetails(data: any): Promise<void> {
        const eventId = data.eventId;
        if (data) {
            this.eventDetails = {...data};
            this.$router.push({
                name: 'hungarian-event-history-details',
                query: {
                    eventId: eventId
                }
            });
        }
    }

    get isDetailsView(): boolean {
        return this.$route.path === '/event-history/event-history-details'? true : false;
    }

    get heading(): Record<string, any> {
        return {
            eventIdentifier: i18n.t('table.id'),
            eventTitle: i18n.t('table.title'),
            eventStart: i18n.t('table.start'),
            eventStop: i18n.t('table.end'),
            availableCapacity: i18n.t('table.availability'),
            unavailableCapacity: i18n.t('table.unAvailability'),
            installedCapacity: i18n.t('table.installedCapacity'),
            updatedDate: i18n.t('table.updatedDate'),
            updatedBy: i18n.t('table.updatedBy'),
            isRead: i18n.t('table.published'),
            action: '',
        };
    }
}

