import { Ramp } from './event-ramp';

export interface CcgtComponent {
    id: number;
    eventId: number;
    machineSid: number;
    name: string;
    shortName: string;
    pMax: number;
    pMin?: number;
}

export interface WorkflowActivityDto {
    'id': number;
    'eventId': number;
    'workflowId': string;
    'completedByUserId': number;
    'activityId': string;
    'completedOn': string;
    'comment': string;
    'createdAt': string;
    'completedAction': string;
    'activityName': string;
    'validFrom': string;
    'validTo': string;
}

export interface AdhocHeaderDto {
    'eventId': number;
    'minimumOutput': number;
    'maximumOutput': number;
    'adhocDescription': string;
    'validFrom': string;
    'validTo': string;
}

export interface EventHeader {
    'eventId': number | undefined | null;
    'createdAt': string | undefined | null;
    'createdBy': string | undefined | null;

    'updatedAt': string | undefined | null;
    'updatedBy': string | undefined | null;

    'creatorId'?: number;
    'approverFourEyePrincipleId'?: number;
    'lastEditedById'?: number;

    'enumEventReasonEexName': string | undefined | null;
    'enumEventReasonName': string | undefined | null;
    'enumEventStatusName': string | undefined | null;
    'enumEventReasonEexSid': number;
    'enumEventReasonSid': number;
    'enumEventStatusSid': number;
    'eventPlantSid': number;
    'enumAreaSsid': number;
    'eventMachineSid': number;
    'enumEventTypeSid': number;


    'eventIdentifier': string;
    'eventTitle': string;
    'eventDescription': string;
    'netCapacityTec': number;
    'availCapacityUnit': number;
    'unAvailCapacityUnit': number;
    'minCapacityEvent': number;
    'netMinCapacityTec': number;

    'utcStartTime': string;
    'utcEndTime': string;
    'expectedUtcEndTime': string;

    'startRamp'?: Ramp;
    'endRamp'?: Ramp;
    'downRampDefinitionSid'?: number;
    'upRampDefinitionSid'?: number;
    'validFrom': string;
    'validTo': string;

    'isMustRun': boolean;
    'isTestRun': boolean;

    'plannedUnplannedBoundry'?: number;

    'workflowId'?: string;
    'eventWorkflows'?: Array<WorkflowActivityDto>;

    'ccgtComponentIds'?: number[];
    'ccgtComponentsMappingDtos'?: CcgtComponent[];

    'adhocHeader': AdhocHeaderDto;
}

export const EventColumnHeaders = [
    'eventIdentifier',
    'eventTitle',
    'utcStartTime',
    'utcEndTime',
    'availCapacityUnit',
    'unAvailCapacityUnit',
    'updatedAt',
    'action',
]

export const EventColumnHeadersSortable = [
    'eventIdentifier',
    'eventTitle',
    'utcStartTime',
    'utcEndTime',
    'availCapacityUnit',
    'unAvailCapacityUnit',
    'updatedAt',
]

export interface EventListResult {
    'items': Array<EventHeader>;
    'totalPages': number;
    'totalCount': number;
    'hasNextPage': boolean;
    'hasPreviousPage': boolean;
    'currentPage': number;
}

export interface EventHeaderFilterModel {
    EventIdentifier?: string;
    EventTitle?: string;
    EventStartFrom?: string;
    EventStartTo?: string;
    EventEndFrom?: string;
    EventEndTo?: string;
    RPMaxFrom?: number;
    RPMaxTo?: number;
    EventReason?: number;
    EventStatus?: number[];
    MachineSids?: number[];
    PlantSids?: number[];
    AreaSids?: number[];
    MostRecent?: boolean;
    Sort?: 'ASC' | 'DESC' | undefined;
    SortProperty?: string;
    page: number;
    size: number;
    Term?: string;
    TermToLower?: string;
}

export interface ExistingEventsListResult {
    items: Array<ExistingEventModel>;
}

export interface ExistingEventModel {
    eventId: number;
    eventIdentifier: string;
    eventTitle: string;
}

export interface ExisitingEventsFilterModel {
    UTCStartTime: string;
    UTCEndTime: string;
    EventStatus?: number[];
    MachineSids?: number[];
}
