









import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({
    name: 'boolean-label',
})
export default class BooleanLabel extends Vue {
    @Prop({ required: true })
    private titles!: {
        textTrue: string;
        textFalse: string;
    };
    @Prop({ required: true })
    private value!: boolean;

    @Prop({
        type: Object,
        default: () => ({
            textTrue: 'green',
            textFalse: 'red',
        }),
    })
    private decoration!: {
        textTrue: string;
        textFalse: string;
    };

    private generateStyle(): object {
        return {
            color: this.value ? this.decoration.textTrue : this.decoration.textFalse,
        };
    }
}
