



























































































import { EventBus, isDate } from '@/utils';
import i18n from '@/utils/i18n-instance';
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';

@Component({
    name: 'event-form-text-feilds',
})
export default class EventFormTextFields extends Vue {
    @Prop({ default: true })
    private showCopyButton!: boolean;

    @Prop({ required: true })
    private type!: string;

    @Prop({ required: true })
    private value!: string;

    @Prop({ default: '' })
    private hungarainTranValue!: string

    @Prop({ default: false })
    private translateValue!: boolean;

    @Prop({ default: true })
    private isValid!: boolean;

    @Prop({ default: '' })
    private translationKey!: string;

    @Prop({ required: true })
    private label!: string;

    @Prop({ default: '' })
    private placeholder!: string;

    @Prop({default: false})
    private readonly!: boolean;

    @Prop({ required: true })
    private id!: string;

    @Prop({ default: false })
    private textarea!: boolean;

    @Prop({ default: 0 })
    private min!: number;

    @Prop({ required: false })
    private max!: number;

    private created(): void {
        this.hungarianLabel;
        this.hungarianValue;
    }

    private copyField(id: string): void {
        const textElement: any = document.getElementById(id);
        if (textElement) {
            textElement.select();
            document.execCommand('copy');
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'copiedToClipboard');
        } else {
            throw new Error('Copy text element is undefined');
        }
    }

    get propValue(): string {
        return this.value;
    }

    set propValue(value: string) {
        this.$emit('update-value', value, this.label);
    }

    get hungarianPlaceholder(): string {
        return this.getTranslatedValue(this.placeholder);
    }

    get hungarianLabel(): string {
        return this.getTranslatedValue(this.label);
    }

    get hungarianValue(): string | number {
        if (this.translateValue && !this.translationKey) {
            return this.getTranslatedValue(this.value);
        }
        if (this.translationKey) {
            return this.getTranslatedValue(this.translationKey);
        }
        if (this.textarea && !this.translateValue) {
            return this.hungarainTranValue;
        }
        return this.value;
    }

    get tooltipText(): object {
        if (this.type === 'number' || isDate(this.propValue)) {
            return {
                value: i18n.t('copyValue'),
                showIcon: false
            }
        }

        return {
            value: i18n.t('copyHungarianValue'),
            englishValue: i18n.t('copyEnglishValue'),
            showIcon: true
        };
    }

    private getTranslatedValue(key: string): string {
        return i18n.t(key, 'hu-HU').toString();
    }
}
