/* eslint-disable */

export const MOCK_EVENT_DATA = {
    result: {
        country: 'Hungary',
        marketParticipant: 'Uniper Hungary Energetikai Ltd.',
        market: [
            'Electricity',
            'Natural gas',
            'Other'
        ],
        eventStatus: [
            'Active',
            'Dismissed',
            'Inactive'
        ],
        typeOfUnavailability: [
            'Planned',
            'Unplanned'
        ],
        typeOfEvent: [
            'Production unavailability',
            'Transmission unavailability',
            'Consumption unavailability',
            'Other unavailability'
        ],
        unit: 'MW',
        installedCapacity: 428,
        fuel: 'Natural gas',
        biddingZone: '10YHU-MAVIR----U',
        affectedAssetOrUnit: 'Gönyü Power Plant',
        EICCode: '15WGONYU-----PP5',
        marketParticipantCode: 'A0000211P.HU',
        other: [
            'Unavailable Capacity',
            'Available Capacity',
            'Reason of Unavailability',
            'Remarks',
            'additional information',
            'Publication title'
        ],
        reasonOptions: [
            'Gas turbine trip',
            'Steam turbine trip',
            'HRSG failure',
            'Condenser failure',
            'GT start up failure',
            'Generator malfunction',
            'Cooling water supply failure',
            'I&C failure',
            'Lube oil supply problem',
            'External factor',
            'Unplanned maintenance',
            'Strike',
            'Fuel supply problem',
            'Fire alarm',
            'Electrical failure'
        ],
    },
    statusCode: 200,
    statusDescription: 'OK'
}

export enum EnumEventTypeSid {
    Unavailability = 703,
    TestRun = 704,
    MustRun = 705
}

export const TsoReasonsByEventTypeSid = {
    [EnumEventTypeSid.Unavailability] : [
        'plannedUnavailability',
        'unplannedUnavailability',
        'eventPublication',
        'preStrikeNotice',
        'strikeRiskResolved',
        'energyVolumeRestrictions'],
    [EnumEventTypeSid.TestRun]: ['test'],
    [EnumEventTypeSid.MustRun]: ['mustRun']
}

export const MOCK_EVENT_TSO = {
    result: {
        result: [
            {
                categoryName: 'Country',
                subCategoryName: [
                    'Hungary'
                ]
            },
            {
                categoryName: 'Market participant',
                subCategoryName: [
                    'Uniper Hungary Energetikai Ltd.'
                ]
            },
            {
                categoryName: 'Market',
                subCategoryName: [
                    'Electricity',
                    'Natural gas',
                    'Other'
                ]
            },
            {
                categoryName: 'Event Status',
                subCategoryName: [
                    'Active',
                    'Dismissed',
                    'Inactive'
                ]
            },
            {
                categoryName: 'Type of Unavailability',
                subCategoryName: [
                    'Planned',
                    'Unplanned'
                ]
            },
            {
                categoryName: 'Type of Event',
                subCategoryName: [
                    'Production unavailability',
                    'Transmission unavailability',
                    'Consumption unavailability',
                    'Other unavailability'
                ]
            },
            {
                categoryName: 'Unit of measurement',
                subCategoryName: [
                    'MW'
                ]
            },
            {
                categoryName: 'Unavailable Capacity',
                subCategoryName: [
                    null
                ]
            },
            {
                categoryName: 'Available Capacity',
                subCategoryName: [
                    null
                ]
            },
            {
                categoryName: 'Installed capacity',
                subCategoryName: [
                    '428'
                ]
            },
            {
                categoryName: 'Reason of Unavailability',
                subCategoryName: [
                    null
                ]
            },
            {
                categoryName: 'Remarks, additional information',
                subCategoryName: [
                    null
                ]
            },
            {
                categoryName: 'Fuel',
                subCategoryName: [
                    'natural gas'
                ]
            },
            {
                categoryName: 'Bidding Zone',
                subCategoryName: [
                    '10YHU-MAVIR----U'
                ]
            },
            {
                categoryName: 'Affected Asset or Unit',
                subCategoryName: [
                    'Gönyü Power Plant'
                ]
            },
            {
                categoryName: 'Affected Asset or Unit EIC Code',
                subCategoryName: [
                    '15WGONYU-----PP5'
                ]
            },
            {
                categoryName: 'Market Participant Code',
                subCategoryName: [
                    'A0000211P.HU'
                ]
            },
            {
                categoryName: 'Publication title',
                subCategoryName: [
                    null
                ]
            }
        ]
    },
    statusCode: 200,
    statusDescription: 'OK'
}

export const MOCK_REASONS_DATA = {
    result: {
        result: [
            {
                reasonCategoryName: 'Planned unavailability',
                reasonSubCategoryName: [
                    'planned maintenance'
                ],
                description: 'Due to planned maintenance planned unavailability occurred.'
            },
            {
                reasonCategoryName: 'Unplanned unavailability',
                reasonSubCategoryName: [
                    'gas turbine trip',
                    'steam turbine trip',
                    'HRSG failure',
                    'condenser failure',
                    'GT start up failure',
                    'generator malfunction',
                    'cooling water supply failure',
                    'I&C failure',
                    'lube oil supply problem',
                    'external factor',
                    'unplanned maintenance',
                    'strike',
                    'fuel supply problem',
                    'fire alarm',
                    'electrical failure'
                ],
                description: 'Due to unnplanned unavailability occurred.'
            },
            {
                reasonCategoryName: 'Event publication on the Hungarian Inside Information website technically not possible',
                reasonSubCategoryName: [
                    'gas turbine trip',
                    'steam turbine trip',
                    'HRSG failure',
                    'condenser failure',
                    'GT start up failure',
                    'generator malfunction',
                    'cooling water supply failure',
                    'I&C failure',
                    'lube oil supply problem',
                    'external factor',
                    'unplanned maintenance',
                    'strike',
                    'fuel supply problem',
                    'fire alarm',
                    'electrical failure'
                ],
                description: 'Due to unnplanned unavailability occurred, but we temporarily cannot publish infromation on the Hungarian Inside Information website (insideinformation.hu). We will inform the market when the issue is resolved.'
            },
            {
                reasonCategoryName: 'Test',
                reasonSubCategoryName: [
                    null
                ],
                description: 'The Gönyü power plant will carry out plant tests in the given period. Varying load can be expected during the test between 0 - 400 MW.'
            },
            {
                reasonCategoryName: 'Must Run 2 (with Capacity)',
                reasonSubCategoryName: [
                    null
                ],
                description: 'The Gönyü power plant will be in a must run (up to 350 MW) in the given period.'
            },
            {
                reasonCategoryName: 'Pre-strike notice',
                reasonSubCategoryName: [
                    null
                ],
                description: 'Pre-strike notice in Gönyü Power Plant covering the given period. Possible impact on the load over that period.'
            },
            {
                reasonCategoryName: 'Strike Risk resolved',
                reasonSubCategoryName: [
                    null
                ],
                description: 'The strike risk in Gönyü Power Plant has been resolved. Normal operation is expected.'
            },
            {
                reasonCategoryName: 'Energy Volume restrictions',
                reasonSubCategoryName: [
                    'low water levels of the Danube river'
                ],
                description: 'There may be irregular operation in unit Gönyü in the given period due to low water levels of the Danube river.'
            }
        ]
    },
    statusCode: 200,
    statusDescription: 'OK'
}
