
















import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({
    name: 'pebble-check-box',
})
export default class PebbleCheckBox extends Vue {
    @Prop({ required: true })
    private name!: string;

    @Prop({ required: true })
    private value!: boolean;

    @Prop({ required: true })
    private label!: string;

    @Prop({ default: '' })
    private title!: string;

    @Prop({ default: false })
    private required!: boolean;

    @Prop()
    private disabled!: boolean;

    get propValue(): boolean {
        return this.value;
    }

    set propValue(value: boolean) {
        this.$emit('update-prop', value, this.name);
    }

    private change(val: boolean): void {
        this.$emit('on-change', val, this.name);
        this.$forceUpdate();
    }
}
