




































import { Component, Vue} from 'vue-property-decorator';
import Card from '@/components/cards/card.vue';
import EventDetailsForm from '@/views/details-view/event-details-form.vue';
import EventFormDropdown from '@/components/pebble-form-element/event-form-drop-down.vue';
import { EventHeader, PebbleDropDown } from '@/models';
import { EventService } from '@/services';
import { EventBus } from '@/utils';
import i18n from '@/utils/i18n-instance';
import { EnumEventTypeSid, TsoReasonsByEventTypeSid } from '@/utils/mocks/event-detail-response';

@Component({
    name: 'hungarian-event-details',
    components: {
        Card,
        EventDetailsForm,
        eventFormDropDown: EventFormDropdown,
    }
})
export default class HungarianEventDetails extends Vue {

    private eventId = '';
    private eventService: EventService = new EventService();
    private eventDetails: EventHeader  | null = null;
    private selectedReason = '';

    private async mounted(): Promise<void> {
        this.eventId = this.$route.query.eventId.toString();
        await this.getEventDetails();
    }

    get eventTsoReasons(): PebbleDropDown[] {
        const eventTypeSid: EnumEventTypeSid = this.eventDetails?.enumEventTypeSid ?? EnumEventTypeSid.Unavailability
        const tsoReasons = TsoReasonsByEventTypeSid[eventTypeSid]

        return tsoReasons.map((reason: string) => ({
            label: i18n.t(reason, 'hu-HU').toString(),
            value: reason,
            translationKey: reason
        }));
    }

    private onSelect(value: string): void {
        this.selectedReason = value;
        EventBus.$emit(EventBus.VIEWS.EVENT_DETAILS.REASON_CHANGED, value);
    }

    private async getEventDetails(): Promise<void> {
        this.$store.commit('loading');
        try {
            const details = (await this.eventService.getEventById(+this.eventId)).result;
            this.eventDetails = details;
        } catch (e) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingEventDetails');
        } finally {
            this.$store.commit('loading');
        }
    }

    private back(): void {
        this.$router.back();
    }
}

