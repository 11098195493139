import { PebbleDropDown } from '@/models';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const applicationState: {
    isLoading: boolean;
    isProductionEnv: boolean;
    marketOptions: PebbleDropDown[];
} = {
    isLoading: false,
    isProductionEnv: process.env.VUE_APP_ENVIRONMENT === 'prd',
    marketOptions: [],
};

export default new Vuex.Store({
    state: applicationState,
    mutations: {
        loading(state): void {
            state.isLoading = !state.isLoading;
        },
        setMarketOptions(state, data): void {
            return state.marketOptions = data;
        }
    },
    actions: {},
    modules: {},
    getters: {
        isLoading(state): boolean {
            return state.isLoading;
        },
        isProductionEnv(state: typeof applicationState): boolean {
            return state.isProductionEnv;
        },
        getMarketOptions(state): PebbleDropDown[] {
            return state.marketOptions;
        }
    },
});
