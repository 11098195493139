import i18n from './i18n-instance';

export const MARKET_TRANSLATION_KEYS = [
    {
        key: 'marketOptions.electricity',
        value: i18n.t('marketOptions.electricity').toString()
    },
    {
        key: 'marketOptions.naturalGas',
        value: i18n.t('marketOptions.naturalGas').toString()
    },
    {
        key: 'marketOptions.other',
        value: i18n.t('marketOptions.other').toString()
    },
];

export const EVENT_STATUS_TRANSLATION_KEYS = [
    {
        key: 'eventStatusOptions.active',
        value: i18n.t('eventStatusOptions.active').toString()
    },
    {
        key: 'eventStatusOptions.dismissed',
        value: i18n.t('eventStatusOptions.dismissed').toString()
    },
    {
        key: 'eventStatusOptions.inactive',
        value: i18n.t('eventStatusOptions.inactive').toString()
    },
];

export const UNAVAILABILITY_TRANSLATION_KEYS = [
    {
        key: 'unavailabilityOptions.planned',
        value: i18n.t('unavailabilityOptions.planned').toString()
    },
    {
        key: 'unavailabilityOptions.unplanned',
        value: i18n.t('unavailabilityOptions.unplanned').toString()
    },
];

export const EVENT_TRANSLATION_KEYS = [
    {
        key: 'eventOptions.productionUnavailability',
        value: i18n.t('eventOptions.productionUnavailability').toString()
    },
    {
        key: 'eventOptions.transmissionUnavailability',
        value: i18n.t('eventOptions.transmissionUnavailability').toString()
    },
    {
        key: 'eventOptions.consumptionUnavailability',
        value: i18n.t('eventOptions.consumptionUnavailability').toString()
    },
    {
        key: 'eventOptions.otherUnavailability',
        value: i18n.t('eventOptions.otherUnavailability').toString()
    },
];

export const REASON_TRANSALATION_KEYS = [
    {
        key: 'reasonOptions.gasTurbineTrip',
        value: i18n.t('reasonOptions.gasTurbineTrip').toString()
    },
    {
        key: 'reasonOptions.steamTurbineTrip',
        value: i18n.t('reasonOptions.steamTurbineTrip').toString()
    },
    {
        key: 'reasonOptions.hrsgFailure',
        value: i18n.t('reasonOptions.hrsgFailure').toString()
    },
    {
        key: 'reasonOptions.condenserFailure',
        value: i18n.t('reasonOptions.condenserFailure').toString()
    },
    {
        key: 'reasonOptions.gtStartFailure',
        value: i18n.t('reasonOptions.gtStartFailure').toString()
    },
    {
        key: 'reasonOptions.generatorMalfunction',
        value: i18n.t('reasonOptions.generatorMalfunction').toString()
    },
    {
        key: 'reasonOptions.coolingWaterSupplyFailure',
        value: i18n.t('reasonOptions.coolingWaterSupplyFailure').toString()
    },
    {
        key: 'reasonOptions.icFailure',
        value: i18n.t('reasonOptions.icFailure').toString()
    },
    {
        key: 'reasonOptions.lubeOilSupplyProblem',
        value: i18n.t('reasonOptions.lubeOilSupplyProblem').toString()
    },
    {
        key: 'reasonOptions.externalFactor',
        value: i18n.t('reasonOptions.externalFactor').toString()
    },
    {
        key: 'reasonOptions.unplannedMaintenance',
        value: i18n.t('reasonOptions.unplannedMaintenance').toString()
    },
    {
        key: 'reasonOptions.strike',
        value: i18n.t('reasonOptions.strike').toString()
    },
    {
        key: 'reasonOptions.fuelSupplyProblem',
        value: i18n.t('reasonOptions.fuelSupplyProblem').toString()
    },
    {
        key: 'reasonOptions.fireAlarm',
        value: i18n.t('reasonOptions.fireAlarm').toString()
    },
    {
        key: 'reasonOptions.electricalFailure',
        value: i18n.t('reasonOptions.electricalFailure').toString()
    },
];
