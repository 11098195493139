import { TsoData, TsoEventModel } from '@/models/event-tso';
import jsPDF from 'jspdf';
import i18n from './i18n-instance';
import { font } from './hungarian-font-pdf';
import autoTable from 'jspdf-autotable';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { EventBus } from './eventbus';

export class EventTSoDataHelper {
    public static getModifiedApiResponse(apiResponse: TsoEventModel[]): any {
        const tsoData: TsoData = {
            mustRunCapacity: 0,
            testCapacity: 0,
            affectedAsset: '',
            affectedAssetCode: '',
            availableCapacity: 0,
            biddingZone: '',
            country: '',
            eventStart: '',
            eventStatus: '',
            eventStop: '',
            fuel: '',
            installedCapacity: 0,
            market: '',
            marketParticipant: '',
            marketParticipantCode: '',
            publicationTitle: '',
            reasonOfUnavailability: '',
            remarks: '',
            typeOfEvent: '',
            typeOfUnavailability: '',
            unavailableCapacity: 0,
            unitOfMeasurement: '',
            enumEventReasonEexSid: 0,
            isRead: false,
            selectedEventStatus: '',
            selectedMarket: '',
            selectedTypeOfEvent: '',
            selectedTypeOfUnavailability: '',
            eventExists: false,
            eventId: 0,
            eventIdentifier: ''
        };
        if (tsoData) {
            apiResponse.forEach((data: TsoEventModel) => {
                for (const field of Object.keys(tsoData)) {
                    if (data.categoryName === i18n.t(field, 'en-GB')) {
                        if (data.subCategoryName.length === 1) {
                            tsoData[field] = data.subCategoryName[0];
                        } else {
                            tsoData[field] = data.subCategoryName;
                        }
                    }
                }
            })
            return tsoData;
        }
    }

    public static downloadData(data: any, title: string, translatedValue: string): void {

        const doc = {
            content: [
                { text: 'Event- ' + title, style: 'title'},
                {
                    style: 'tableExample',
                    table: {
                        widths: [230, 'auto'],
                        body: [
                            [{text: 'Title', style: 'tableHeader'}, {text: 'Value', style: 'tableHeader'}],
                            [
                                {text:  i18n.t('country', 'en-GB'), style: 'columnStyle'},
                                data.country
                            ],
                            [
                                {text:  i18n.t('marketParticipant', 'en-GB'), style: 'columnStyle'},
                                data.marketParticipant
                            ],
                            [
                                {text:  i18n.t('market', 'en-GB'), style: 'columnStyle'},
                                i18n.t(data.selectedMarket, 'en-GB')
                            ],
                            [
                                {text:  i18n.t('eventStatus', 'en-GB'), style: 'columnStyle'},
                                i18n.t(data.selectedEventStatus, 'en-GB')
                            ],
                            [
                                {text:  i18n.t('typeOfUnavailability', 'en-GB'), style: 'columnStyle'},
                                i18n.t(data.selectedTypeOfUnavailability, 'en-GB')
                            ],
                            [
                                {text:  i18n.t('typeOfEvent', 'en-GB'), style: 'columnStyle'},
                                i18n.t(data.selectedTypeOfEvent, 'en-GB')
                            ],
                            [
                                {text:  i18n.t('eventStart', 'en-GB'), style: 'columnStyle'},
                                data.eventStart
                            ],
                            [
                                {text:  i18n.t('eventStop', 'en-GB'), style: 'columnStyle'},
                                data.eventStop
                            ],
                            [
                                {text:  i18n.t('unitOfMeasurement', 'en-GB'), style: 'columnStyle'},
                                data.unitOfMeasurement
                            ],
                            [
                                {text:  i18n.t('unavailableCapacity', 'en-GB'), style: 'columnStyle'},
                                data.unavailableCapacity
                            ],
                            [
                                {text:  i18n.t('availableCapacity', 'en-GB'), style: 'columnStyle'},
                                data.availableCapacity
                            ],
                            [
                                {text:  i18n.t('installedCapacity', 'en-GB'), style: 'columnStyle'},
                                data.installedCapacity
                            ],
                            [
                                {text:  i18n.t('reasonOfUnavailability', 'en-GB'), style: 'columnStyle'},
                                data.reasonOfUnavailability
                            ],
                            [
                                {text:  i18n.t('remarks', 'en-GB'), style: 'columnStyle'},
                                i18n.t(data.remarks, 'en-GB')
                            ],
                            [
                                {text:  i18n.t('fuel', 'en-GB'), style: 'columnStyle'},
                                data.fuel
                            ],
                            [
                                {text:  i18n.t('biddingZone', 'en-GB'), style: 'columnStyle'},
                                data.biddingZone
                            ],
                            [
                                {text:  i18n.t('affectedAsset', 'en-GB'), style: 'columnStyle'},
                                i18n.t('field.affectedAsset', 'en-GB')
                            ],
                            [
                                {text:  i18n.t('affectedAssetCode', 'en-GB'), style: 'columnStyle'},
                                i18n.t('field.affectedAssetCode', 'en-GB')
                            ],
                            [
                                {text:  i18n.t('marketParticipantCode', 'en-GB'), style: 'columnStyle'},
                                data.marketParticipantCode
                            ],
                            [
                                {text:  i18n.t('publicationTitle', 'en-GB'), style: 'columnStyle'},
                                i18n.t(data.publicationTitle, 'en-GB')
                            ],
                        ]
                    }
                },

                { text: 'Event- ' + title, pageBreak: 'before', style: 'title'},
                {
                    style: 'tableExample',
                    table: {
                        widths: [230, 'auto'],
                        body: [
                            [{text: 'Title', style: 'tableHeader'}, {text: 'Value', style: 'tableHeader'}],
                            [
                                {text: this.translateToHun('country'), style: 'columnStyle'},
                                this.translateToHun('field.country')
                            ],
                            [
                                {text: this.translateToHun('marketParticipant'), style: 'columnStyle'},
                                this.translateToHun('field.marketParticipant')
                            ],
                            [
                                {text: this.translateToHun('market'), style: 'columnStyle'},
                                this.translateToHun(data.selectedMarket)
                            ],
                            [
                                {text: this.translateToHun('eventStatus'), style: 'columnStyle'},
                                this.translateToHun(data.selectedEventStatus)
                            ],
                            [
                                {text: this.translateToHun('typeOfUnavailability'), style: 'columnStyle'},
                                this.translateToHun(data.selectedTypeOfUnavailability)
                            ],
                            [
                                {text: this.translateToHun('typeOfEvent'), style: 'columnStyle'},
                                this.translateToHun(data.selectedTypeOfEvent)
                            ],
                            [
                                {text: this.translateToHun('eventStart'), style: 'columnStyle'},
                                data.eventStart
                            ],
                            [
                                {text: this.translateToHun('eventStop'), style: 'columnStyle'},
                                data.eventStop
                            ],
                            [
                                {text: this.translateToHun('unitOfMeasurement'), style: 'columnStyle'},
                                data.unitOfMeasurement
                            ],
                            [
                                {text: this.translateToHun('unavailableCapacity'), style: 'columnStyle'},
                                data.unavailableCapacity
                            ],
                            [
                                {text: this.translateToHun('availableCapacity'), style: 'columnStyle'},
                                data.availableCapacity
                            ],
                            [
                                {text: this.translateToHun('installedCapacity'), style: 'columnStyle'},
                                data.installedCapacity
                            ],
                            [
                                {text: this.translateToHun('reasonOfUnavailability'), style: 'columnStyle'},
                                translatedValue
                            ],
                            [
                                {text: this.translateToHun('remarks'), style: 'columnStyle'},
                                this.translateToHun(data.remarks)
                            ],
                            [
                                {text: this.translateToHun('fuel'), style: 'columnStyle'},
                                this.translateToHun('field.fuel')
                            ],
                            [
                                {text: this.translateToHun('biddingZone'), style: 'columnStyle'},
                                data.biddingZone
                            ],
                            [
                                {text: this.translateToHun('affectedAsset'), style: 'columnStyle'},
                                this.translateToHun('field.affectedAsset')
                            ],
                            [
                                {text: this.translateToHun('affectedAssetCode'), style: 'columnStyle'},
                                this.translateToHun('field.affectedAssetCode')
                            ],
                            [
                                {text: this.translateToHun('marketParticipantCode'), style: 'columnStyle'},
                                data.marketParticipantCode
                            ],
                            [
                                {text: this.translateToHun('publicationTitle'), style: 'columnStyle'},
                                this.translateToHun(data.publicationTitle)
                            ],
                        ]
                    }
                },
            ],
            styles: {
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black',
                    fillColor: '#229AFE', // uniper-blue
                    alignment: 'center'
                },
                columnStyle: {
                    bold: true,
                    fontSize: 13,
                },
                title: {
                    margin: [0, 5, 5, 15],
                    bold: true,
                    fontSize: 16,
                    alignment: 'center'
                },
                tableExample: {
                    margin: [0, 5, 0, 15]
                },
            }
        }
        /* eslint-disable */
        const pdfMake = require('pdfmake/build/pdfmake.js')
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.createPdf(doc).getBuffer((buffer: any) => {
            const blobPDF = new Blob([buffer], { type: 'application/pdf' });
            const a = window.document.createElement('a');

            a.href = window.URL.createObjectURL(blobPDF);
            a.download = title;

            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);

            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'pdfDownloadedSuccessfully');
        });

    }

    private static translateToHun(key: string): string {
        return i18n.t(key, 'hu-HU').toString();
    }
}
