

































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import EventFormTextFields from '@/components/pebble-form-element/event-form-text-feilds.vue';
import EventFormDropdown from '@/components/pebble-form-element/event-form-drop-down.vue';
import Card from '@/components/cards/card.vue';
import PebbleCheckBox from '@/components/pebble-form-element/pebble-checkbox.vue';
import { EventHistoryService } from '@/services/__mocks__';
import { EventHistoryDetail, EventHistoryList } from '@/models';
import { EventBus, EventTSoDataHelper, EVENT_STATUS_TRANSLATION_KEYS, EVENT_TRANSLATION_KEYS, MARKET_TRANSLATION_KEYS, UNAVAILABILITY_TRANSLATION_KEYS } from '@/utils';
import i18n from '@/utils/i18n-instance';
import { REASON_TRANSALATION_KEYS } from '@/utils/option-translation-keys';
import { HungarianEventService } from '@/services';

@Component({
    name: 'event-history-details',
    components: {
        eventFormTextFeilds: EventFormTextFields,
        eventFormDropDown: EventFormDropdown,
        pebbleCheckBox: PebbleCheckBox,
        Card,
    }
})
export default class EventHistoryDetails extends Vue {

    private eventId = '';
    private eventService = new HungarianEventService();
    private eventHistoryDetails: EventHistoryDetail | null = null;

    private async mounted(): Promise<void> {
        this.eventId = this.$route.query.eventId.toString();
        await this.getEventHistoryDetails();
        this.translatedDescription;
    }

    private back(): void {
        this.$router.push({
            name: 'event-history',
        });
    }

    private async getEventHistoryDetails(): Promise<void> {
        this.$store.commit('loading');
        try {
            const data = (await this.eventService.getEventHistoryDetails({EventId: +this.eventId})).result;
            this.eventHistoryDetails = data.result;
            this.getTranslatedValues();
        } catch (e) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'error.gettingEventDetails');
        } finally {
            this.$store.commit('loading');
        }
    }

    private getTranslatedValues(): void {
        if (this.eventHistoryDetails) {
            this.eventHistoryDetails.selectedMarket =
                this.getOptionsTranslationKey(this.eventHistoryDetails.market, MARKET_TRANSLATION_KEYS);
            this.eventHistoryDetails.selectedEventStatus =
                this.getOptionsTranslationKey(this.eventHistoryDetails.eventStatus, EVENT_STATUS_TRANSLATION_KEYS);
            this.eventHistoryDetails.selectedTypeOfUnavailability =
                this.getOptionsTranslationKey(this.eventHistoryDetails.typeOfUnavailability, UNAVAILABILITY_TRANSLATION_KEYS);
            this.eventHistoryDetails.selectedTypeOfEvent =
                this.getOptionsTranslationKey(this.eventHistoryDetails.typeOfEvent, EVENT_TRANSLATION_KEYS);
            this.eventHistoryDetails.remarksTransKey =
                this.getOptionsTranslationKey(this.eventHistoryDetails.remarks, REASON_TRANSALATION_KEYS);
            this.eventHistoryDetails.publicationTitleTransKey =
                this.getTranslationKey(this.eventHistoryDetails.publicationTitle, 'en-GB');


            if (this.eventHistoryDetails.publicationTitleTransKey === 'energyVolumeRestrictions') {
                this.eventHistoryDetails.remarksTransKey = 'field.energyVolumeRemark'
            }

            if (this.eventHistoryDetails.publicationTitleTransKey === 'plannedUnavailability') {
                this.eventHistoryDetails.remarksTransKey = 'field.plannedUnavailabilityRemark'
            }
        }
    }

    get translatedDescription(): string {
        if (this.eventHistoryDetails && this.eventHistoryDetails.publicationTitleTransKey) {
            const hungarianDescription = i18n.t('field.description.' + this.eventHistoryDetails.publicationTitleTransKey, 'hu-HU').toString();
            const tsoCase = this.eventHistoryDetails.publicationTitleTransKey;
            const subCategoryKey = this.eventHistoryDetails.remarksTransKey;
            if ((tsoCase === 'unplannedUnavailability' || tsoCase === 'eventPublication') && subCategoryKey) {
                return hungarianDescription.
                    replace('be ', 'be ' + i18n.t(subCategoryKey, 'hu-HU').toString() + ' ');
            }
            if (tsoCase === 'mustRun') {
                return hungarianDescription.replace('350', this.eventHistoryDetails.mustRunCapacity);
            }
            if (tsoCase === 'test') {
                return hungarianDescription.replace('400', this.eventHistoryDetails.testCapacity);
            }
            return hungarianDescription;
        }
        return '';
    }

    private async downloadPdf(): Promise<void> {
        this.$store.commit('loading');
        try {
            if (this.eventHistoryDetails ) {

                const title = this.eventHistoryDetails.eventTitle + '-' + this.eventHistoryDetails.eventIdentifier;
                const remarksKey = this.eventHistoryDetails.remarksTransKey;
                const publicationKey = this.eventHistoryDetails.publicationTitleTransKey;

                if (remarksKey) {
                    this.eventHistoryDetails.remarks = remarksKey
                }

                if (publicationKey) {
                    this.eventHistoryDetails.publicationTitle = publicationKey
                }

                EventTSoDataHelper.downloadData(this.eventHistoryDetails, title, this.translatedDescription);
                await this.getEventHistoryDetails();
            }
        } catch (e) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'error.downloadingPdf');
        } finally {
            this.$store.commit('loading');
        }
    }

    private getOptionsTranslationKey(value: string, options: any[]): string | undefined {
        return options.find((data) => data.value === value)?.key;
    }

    private getTranslationKey(message: string, locale: string): string | undefined {
        const localeMessages = i18n.getLocaleMessage(locale);
        return Object.keys(localeMessages).find((key) => localeMessages[key] === message);
    }
}

