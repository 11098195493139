import moment from 'moment-timezone';
import {LANGUAGES} from '@/utils/languages';

/**
 * Helper function to convert the given date into a string representation of local time.
 * @param date
 * @returns String with format: YYYY-MM-DDThh:mm:ss+hh:mm
 *
 * FIXME Use moment.js to solve this more elegantly.
 */
export function toLocalizedIsoString (date: Date): string {
    const tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num: number): string {
            const norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(tzo / 60) +
        ':' + pad(tzo % 60);
}

export function parseDateForBackend (date: Date): string {
    const timestamp = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
    return timestamp.toISOString()
}

export function parseLocalDateForBackend(date: Date): string {
    const timestamp = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
    return toLocalizedIsoString(timestamp)
}

export function parseBackendUtcTimeAsLocal(timestamp: string): Date {
    if (timestamp.charAt(timestamp.length - 1) === 'Z') {
        timestamp = timestamp.substring(0, timestamp.length - 1)
    }

    timestamp = timestamp.split('.')[0];

    const date = new Date(timestamp + '.000Z')
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
}

// Only locale-respecting formats are supported, see moment.js docs
// The timezone param, is telling moment.js what timezone the passed date is.
// Default is UTC.
export function toDisplayDateString(date: string, locale = 'en', format = 'DD.MM.YYYY HH:mm'): string {
    const parsedDate = parseBackendUtcTimeAsLocal(date);
    return moment(parsedDate.toISOString())
        .add(parsedDate.getTimezoneOffset(), 'minutes')
        .locale(locale)
        .format(format)
        .toString();
}

// Calculates the difference between 'now' and the provided date in the form of an object that contains
// the diff and the unit as a translation key to be used later like '5 days ago', '2 hours ago', etc.
// while seconds is the last supported measurement. This is also a sane limitation by moments.js.
// By default moment truncates decimal places, so we will allways check for 0 which is falsy in js
// This will mainly be used by the notifications
/* eslint-disable sonarjs/cognitive-complexity */
export function toDisplayDateDifference(date: Date | string, locale = 'en'): { diff: number; unit: string } {
    const daysDiff = moment(date).locale(locale).diff(moment.now(), 'days');

    if(!daysDiff) {
        const hoursDiff = moment(date).locale(locale).diff(moment.now(), 'hours');

        if (!hoursDiff) {
            const minutesDiff = moment(date).locale(locale).diff(moment.now(), 'minutes');

            if (!minutesDiff) {
                const secondsDiff = moment(date).locale(locale).diff(moment.now(), 'seconds');

                return { diff: secondsDiff, unit: secondsDiff * -1 === 1 ? 'notificationTimes.sec' : 'notificationTimes.secs' };
            }

            return { diff: minutesDiff, unit: minutesDiff * -1 === 1 ? 'notificationTimes.min' : 'notificationTimes.mins' };
        }
        return { diff: hoursDiff, unit: hoursDiff * -1 === 1 ? 'notificationTimes.hour' : 'notificationTimes.hours' };
    }
    return { diff: daysDiff, unit: daysDiff * -1 === 1 ? 'notificationTimes.day' : 'notificationTimes.days' };
}

// A call of format() without a param will use the local timezone.
// moment.js can handle either Date, date strings or milliseconds
export function toLocalIsoString(date: Date | string | number): string {
    return moment(date).format();
}

export function isDate(value: string | number | Date): boolean {
    return (moment(value, true).isValid()) &&
        !isNaN(new Date(value).getTime());
}

export function toLocalIsoFormattedString(date: Date | string | number, showSeconds = false): string {
    const format = showSeconds ? 'DD.MM.YYYY HH:mm:ss' : 'DD.MM.YYYY HH:mm';
    return moment(date).format(format);
}

/**
 * Helper function to get the unix timestamp in the timezone of the moment object.
 * @param date
 */
export function getTime(date: moment.Moment): number {
    const offset = date.utcOffset() * 60 * 1000;
    return date.valueOf() + offset;
}

export function translatedDateString(time: moment.Moment, selectedLanguage: string, format= 'LLLL'): string {
    let localeKey = 'en';
    if (selectedLanguage === LANGUAGES.DE) {
        localeKey = 'de';
    } else if (selectedLanguage === LANGUAGES.NL) {
        localeKey = 'nl';
    } else if (selectedLanguage === LANGUAGES.HU) {
        localeKey = 'hu';
    } else if (selectedLanguage === LANGUAGES.EN) {
        localeKey = 'en';
    }

    return time.clone().locale(localeKey).format(format);
}

/**
 * Helper function to round/floor/ceil a moment.Moment object
 * @param date
 * @param duration
 * @param method
 */
export function roundDate(date: moment.Moment, duration: moment.Duration, method: 'round' | 'floor' | 'ceil'): moment.Moment {
    const internal = date.clone();
    const tz = date.tz();
    if (tz) {
        return moment(Math[method]((+internal) / (+duration)) * (+duration)).tz(tz);
    }
    return moment(Math[method]((+internal) / (+duration)) * (+duration));
}

export const supportedTimes = ['year', 'years', 'day', 'days', 'hours', 'hours', 'min', 'mins', 'sec', 'secs'];


/**
 * RegExp to test a string for a full ISO 8601 Date
 * Does not do any sort of date validation, only checks if the string is according to the ISO 8601 spec.
 *  YYYY-MM-DDThh:mm:ss
 *  YYYY-MM-DDThh:mm:ssTZD
 *  YYYY-MM-DDThh:mm:ss.sTZD
 * @see: https://www.w3.org/TR/NOTE-datetime
 * @type {RegExp}
 */
const ISO_8601_FULL = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;

// using moment(dateCandidate).isValid() is not reliable enough: ('hallo').isValid() == true
export const isValidDate = (dateCandidate: string): boolean => ISO_8601_FULL.test(dateCandidate);

export const getFormattedDateIfValid = (dateCandidate: string): string => {
    return isValidDate(dateCandidate) ? toLocalIsoFormattedString(dateCandidate, true) : dateCandidate;
}

export const selectedLanguageToMomentLocale = (selectedLanguage: typeof LANGUAGES): moment.LocaleSpecifier => {
    let localeKey = 'en-gb';
    if (selectedLanguage === LANGUAGES.DE) {
        localeKey = 'de';
    } else if (selectedLanguage === LANGUAGES.NL) {
        localeKey = 'nl';
    } else if (selectedLanguage === LANGUAGES.HU) {
        localeKey = 'hu';
    } else if (selectedLanguage === LANGUAGES.EN) {
        localeKey = 'en-gb';
    } else {
        throw 'Unknown language!'
    }
    return localeKey;
}
