import { ApiResponse, Application, ApiResponseList } from '@/models';
import Vue from 'vue';
import { sdkConfig } from '@/utils';
import { Environments } from '@coode/fe-sdk';

/**
 * @name AppService
 * @description This class is used to query the identity server for user related data,
 * which is not related to authentication.
 */
export class AppService {
    constructor() {
        const url = sdkConfig.urls?.metaData[process.env.VUE_APP_ENVIRONMENT as Environments].url;
        Vue.prototype.$sdk.$http?.$core.metaData.request.api.setBaseUrl(url + '/api/v1')
    }

    public async getApps(): Promise<ApiResponse<ApiResponseList<Application>>> {
        return (await Vue.prototype.$sdk.$http?.$core.metaData.request.api.get(`use-cases`)).data;
    }
}
