






import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    name: 'card',
})
export default class Card extends Vue {
}
